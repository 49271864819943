import React from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";

const HubFaq = ({ faqs, languageCode }) => {
	return (
		<section className="py-5 py-lg-7">
			<Container>
				<Row>
					<Col xs={12} className="mx-auto">
						<Accordion style={{ borderRadius: "0px" }}>
							{faqs?.map((faq, index) => (
								<Accordion.Item
									eventKey={index.toString()}
									key={faq.id}
									className="custom-accordion-item"
								>
									<Accordion.Header>
										<strong>{faq.question}</strong>
									</Accordion.Header>
									<Accordion.Body>
										<Row>
											<Col lg={8}>{faq.answer}</Col>
										</Row>
									</Accordion.Body>
								</Accordion.Item>
							))}
						</Accordion>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default HubFaq;
