import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import ClientLayout from "../../components/client-Layout";
import HubFaq from "../../components/HubFaq";
import HubHero from "../../components/HubHero";
import { SafeHtmlParser } from "../../components/SafeHtmlParser";
import SplashScreen from "../../components/SplashScreen";
import TranslateComponent from "../../components/TranslateComponent";
import TranslationBanner from "../../components/TranslationBanner";
import withAuth from "../../components/withAuth";

const FaqsClient = ({ data }) => {
	const [clientData, setClientData] = useState(null);
	const [languageCode, setLanguageCode] = useState("en");
	const [heroData, setHeroData] = useState({
		heading: "",
		body: "",
		image: "",
	});
	const [translatedFaqs, setTranslatedFaqs] = useState([]);

	const handleLanguageChange = (code) => {
		setLanguageCode(code);
	};

	const fetchData = async (endpoint) => {
		const token = localStorage.getItem("token");

		try {
			const response = await fetch("/.netlify/functions/fetchData", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ endpoint, token }),
			});

			const result = await response.json();

			if (!response.ok) {
				console.error(`Error fetching ${endpoint} data:`, result.message);
				return null;
			}

			return result;
		} catch (error) {
			console.error("Fetch error:", error);
			return null;
		}
	};

	useEffect(() => {
		const getData = async () => {
			const result = await fetchData("client?_fields=acf&acf_format=standard");

			if (result) {
				setClientData(result);
				// Set the hero data after fetching
				const heroSection = result[0]?.acf?.hero_section;
				setHeroData({
					heading: heroSection?.heading || "",
					body: heroSection?.body || "",
					image: heroSection?.image || "",
				});
			} else {
				setClientData(null);
			}
		};

		getData();
	}, []);

	// Extract data from GraphQL query
	const getInTouchNode = data.allWpClientPage.edges[0]?.node;
	const faqPageFields = getInTouchNode?.faqPageFields;
	const pageHeading = faqPageFields?.heading || "";
	const pageBody = faqPageFields?.body || "";
	const heroImage = faqPageFields?.image.sourceUrl || "";
	const faqs = faqPageFields?.faqs || [];

	// Translate FAQs by rendering them and converting to strings where needed
	useEffect(() => {
		if (faqs.length > 0) {
			const updatedFaqs = faqs.map((faq) => {
				// Translate question and answer into variables first

				// Return updated FAQ object
				return {
					...faq,
					faqFields: {
						...faq.faqFields,
						question: faq.faqFields.question,
						answer: faq.faqFields.answer, // Set the translated answer as plain text
					},
				};
			});
			setTranslatedFaqs(updatedFaqs);
		} else {
			setTranslatedFaqs([]);
		}
	}, [faqs, languageCode]);

	return (
		<div>
			{clientData ? (
				<ClientLayout
					navLinks={clientData[0]?.acf.navbar_links}
					languageCode={languageCode}
				>
					<div>
						<div className="d-md-none">
							<TranslationBanner
								languageCode={languageCode}
								onSelectLanguage={handleLanguageChange}
							/>
						</div>
						<HubHero
							heading={
								<TranslateComponent code={languageCode} text={pageHeading} />
							}
							body={<TranslateComponent code={languageCode} text={pageBody} />}
							image={heroImage}
						/>
						<div className="d-none d-md-block">
							<TranslationBanner
								languageCode={languageCode}
								onSelectLanguage={handleLanguageChange}
							/>
						</div>
						{/* Pass the translated FAQs to HubFaq */}
						<HubFaq
							faqs={translatedFaqs.map((faq) => {
								// Translate question and answer first
								const translatedQuestion = TranslateComponent({
									code: languageCode,
									text: faq.faqFields.question,
								});
								const translatedAnswer = TranslateComponent({
									code: languageCode,
									text: faq.faqFields.answer,
								});

								// Return as objects
								return {
									id: faq.id,
									question: translatedQuestion,
									answer: <SafeHtmlParser htmlContent={translatedAnswer} />,
								};
							})}
						/>{" "}
					</div>
				</ClientLayout>
			) : (
				<SplashScreen />
			)}
		</div>
	);
};

export const query = graphql`
	query {
		allWpClientPage(filter: { slug: { eq: "faqs" } }) {
			edges {
				node {
					id
					faqPageFields {
						heading
						body
						image {
							sourceUrl
						}
						faqs {
							... on WpFaq {
								id
								faqFields {
									question
									answer
								}
							}
						}
					}
				}
			}
		}
	}
`;

export default withAuth(FaqsClient, "client");
